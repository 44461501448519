<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Ajouter un groupe

              <router-link to="stocks">
                <span class="float-right btn btn-info">retour</span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="addgroupe()">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Nom du groupe <span class="obligatoire">*</span>
                      </label>

                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="nom groupe"
                        required
                      />
                    </div>
                  </div>
                  <!--  col-md-4   -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Choisir les membres <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="true"
                        v-model="user_id"
                        :closeOnClick="false"
                        :options="users"
                        :reduce="(users) => users"
                        label="name"
                      />
                    </div>
                  </div>
                  <!--  col-md-6   -->
                </div>
                <button type="submit" class="btn btn-gradient-primary mr-2">
                  Ajouter
                </button>
                <button class="btn btn-light">Annuler</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from "@/axios";

export default {
  name: "addgroupe",

  data() {
    return {
      name: "",
      user_id: [],
      users: [],

    };
  },

  created() {
    this.getuserss();
  },
  methods: {
    

    getuserss() {
      HTTP.get("users/getusers").then(
        (response) => {
          this.users = response.data;
        },
        (err) => {
          this.error = err.response.data.error;
        }
      );
    },


    addgroupe() {

      let groupe = {
          name: this.name,
          user_id: this.user_id,
           _id: this.$route.params.id,
        };
      HTTP.post("groupes/addgroup", groupe).then(
        (response) => {
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: "groupe ajouté",
            });
            this.$router.push("/getgroups");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

  },
};
</script>
